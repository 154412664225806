import { createRouter, createWebHistory } from "vue-router";

const routes = [
    // 官网首页
    {
        path: "/",
        name: "home",
        component: () => import("@/views/Home.vue"),
    },
    // 404
    // {
    //     path: "/:pathMatch(.*)",
    //     name: "404",
    //     component: () => import('@/views/Smt.vue')
    // },
];

const router = createRouter({
    mode:'history',
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    next()
})

export default router;



